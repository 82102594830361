@font-face {
  font-family: 'Twk Medium';
  src: url('../public/fonts/TWKEverettMono-Medium-web.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Twk ExtraBold';
  src: url('../public/fonts/TWKEverett-Extrabold-web (1).woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.logo_text {
  font-family: 'Twk ExtraBold';
}

html,
body {
  /* overflow-x: hidden; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: url('/public/Δ (1).png'), auto;
  background-color: #16161d !important;
  text-transform: uppercase;
  /* scroll-snap-type: y mandatory; */
  scroll-behavior: auto !important;
  font-family: 'Twk Medium' !important;

  /* overflow-y: scroll; */
}
:-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: var(--background);
}

/* html {
  scroll-snap-type: y mandatory;
} */
.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--accent);
  bottom: 100px;
}

a {
  color: inherit;
  text-decoration: none;
}
.pointer-special {
  cursor: url('/public/Δ (1).png'), auto;
}
.small {
  font-size: 10px;
  line-height: 12px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-left: 0px !important;
}

.flex-justify {
  display: flex;
  justify-content: space-between;
}
.flex-justify-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.thubmail_image {
  object-fit: cover;
}
.home_section_container {
  /* overflow-x: hidden; */
  background-color: #16161d;
}

.filteredImage {
  filter: white;
}
.video_conatiner {
  height: 100vh;
  background: #16161d;
  /* overflow-y: scroll */
}
.video_section_home_container {
  position: relative;
  top: 0;
  transition: top 0.3s ease;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
  .video-background iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-background iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
  }
}
.list_insider:first-child {
  padding-bottom: 10px;
}
.list_position {
  left: -32px;
  top: 32px;
}

.thumnail_images_margin {
  margin-bottom: 20px;
  /* border:1px solid gray; */
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 1.2s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.animated_list {
  -webkit-animation: list-expand 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: list-expand 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@-webkit-keyframes list-expand {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes list-expand {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.hide {
  -webkit-animation: list-hide 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: list-hide 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@-webkit-keyframes list-hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes list-hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animated_hero {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.animated_project_hero {
  -webkit-animation: fade-in-project 1.9s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-project 1.9s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 0.3s;
}

@keyframes fade-in-project {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.link_decoration {
  text-decoration: none;
}

.play_svg {
  position: absolute;
  left: 4px;
  top: 5px;
}

.images_box img {
  height: 503px !important;
  object-fit: cover !important;
}

.full-width img {
  height: 1012px;
}
.right-space img {
  height: 500px;
}
.left-space img {
  height: 500px;
}
.left-side img {
  height: 500px;
}
.right-side img {
  height: 500px;
}

.text-lower-case {
  text-transform: lowercase;
}

.video_info_text {
  @media (min-width: 0px) and (max-width: 726px) {
    font-size: 20px !important;
  }
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    transform: scale(0.8, 0.8);
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.animated_entrance {
  animation-name: text-delay;
  animation-timing-function: ease;
}

@-webkit-keyframes text-delay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes text-delay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated_entrance_selected_projects {
  animation-name: text-delay;
  animation-timing-function: ease;
}

@-webkit-keyframes text-delay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes text-delay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.delay-01 {
  animation-delay: 0.5s;
}
.delay-02 {
  animation-delay: 0.8s;
}
.delay-1 {
  animation-delay: 1.1s;
}
.delay-2 {
  animation-delay: 1.3s;
}
.delay-3 {
  animation-delay: 1.6s;
}
.delay-4 {
  animation-delay: 1.8s;
}
.delay-w1 {
  animation-delay: 1.1s;
}
.delay-w2 {
  animation-delay: 1.3s;
}
.delay-w3 {
  animation-delay: 1.6s;
}
.delay-w4 {
  animation-delay: 1.8s;
}
.delay-w5 {
  animation-delay: 2.1s;
}
.delay-w6 {
  animation-delay: 2.3s;
}
.delay-w7 {
  animation-delay: 2.6s;
}
.delay-w8 {
  animation-delay: 2.8s;
}

.delay-w10 {
  animation-delay: 0.3s;
}
.delay-w20 {
  animation-delay: 0.5;
}
.delay-w30 {
  animation-delay: 0.8s;
}
.delay-w40 {
  animation-delay: 1s;
}
.delay-w50 {
  animation-delay: 1.1s;
}
.delay-w60 {
  animation-delay: 1.3s;
}
.delay-w70 {
  animation-delay: 1.5s;
}
.delay-w80 {
  animation-delay: 1.7s;
}

.delay-10 {
  animation-delay: 0.3s;
}
.delay-20 {
  animation-delay: 0.5;
}
.delay-30 {
  animation-delay: 0.8s;
}
.delay-40 {
  animation-delay: 1s;
}
.user_details_text {
  @media (min-width: 672px) and (max-width: 1440px) {
    width: 70%;
  }
}

.video_play_title {
  position: absolute;
}

.images_box {
  padding-bottom: 10px;
}

@media (min-width: 1800px) {
  .custom-xl-3 {
    flex: 0 0 auto !important;
    width: 17.8% !important;
  }
}

@media (min-width: 1800px) {
  .custom-xxl-3 {
    flex: 0 0 auto !important;
    width: 35.6% !important;
  }
}

/* .video_name_row{
    padding:0px 10px;
  } */

@media (min-width: 481px) and (max-width: 768px) {
  .controls_video_play {
    justify-content: center;
  }
}

.project_header {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
}

ul {
  padding-left: 0 !important;
}

.project_index {
  width: 15%;
}
.project_name {
  width: 85%;
}
@media (min-width: 0px) and (max-width: 480px) {
  .custom-xxs-12 {
    width: 100% !important;
  }
}

.image_box {
  padding: 1px 10px;
  background-color: #16161d;
}

.parallax-container {
  position: relative;
  /* height: 180vh; */
  overflow: hidden;
  /* margin-bottom:300px; */
}

.parallax-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.parallax-video video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.parallax-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 1;
}

.small_screen_project_description {
  width: 98%;
  word-wrap: break-word;
}


.custom_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  margin-top: calc(var(--bs-gutter-y) * -1);
}

.thumbnail_image {
  margin-left: calc(-0.5 * var(--bs-gutter-x)) !important;
}

.year_box {
  padding-left: 18px;
}
.cross_icon {
  font-size: 11px;
}
.clear {
  flex-wrap: wrap;
  justify-content: space-between;
}
.clear img {
  width: 100px;
  height: 100px;
}
.empty_space {
  width: 50%;
}
.half-width {
  width: 50%;
}

.full-width {
  width: 100%;
  clear: both; /* Clear the float to start a new row */
}

/* Clearfix to handle floating elements */
.clearfix::after {
  content: '';
  display: table;
  clear: both;
}
.clear img {
  width: 100%;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
}

.full-width {
  width: 100%;
}

.right-space {
  width: 50%;
}

.left-space {
  width: 50%;
  margin-left: 50%;
}
.left-side {
  width: 49.7%;
}
.right-side {
  width: 50%;
}

.clear div {
  margin-bottom: 5px;
}
.left-side img,
.right-space img,
.left-space img,
.right-side img {
  height: auto !important;
}
.full-width img {
  height: auto !important;
}
.disable-scroll-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0);  */
  z-index: 9999; 
  pointer-events: none; 
}

.disable-scroll-overlay.active {
  pointer-events: auto;
  /* background: rgba(255, 255, 255, 0.8); */
}

/*  return t * (2 - t) */

/* t--;
return t * t * t + 1; */



/* ................ */
/* .parallax-container {
  position: relative;
  height: 100vh; 
  overflow: hidden;
} */

/* .parallax-bg {
  position: -webkit-sticky;
  position: sticky;
} */

/* .parallax-content {
  position:relative;
  z-index:99;
} */

/* ................. */


/* .................... */
/* div.sticky {
  position: -webkit-sticky;
  position: fixed;
  top: 0; */
  /* background-color: yellow; */
  /* padding: 0px;
  font-size: 20px;
 height:2000px;
} */
.bottom_text{
position:relative;
z-index:99;
background:red;
}
/* ............ */




.header_container{
  padding: 0px 10px;
  overflow: hidden;
}